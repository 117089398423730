.core-container-combat {
	/* display: grid;
	grid-template-columns: 1fr; */
	height: 100%;
}

.combat-container-content {
	position: relative;
}

.combat-container-buttons {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.mobile-layout .combat-container-buttons {
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	width: 100%;
}

.mobile-layout .combat-container-buttons img {
	display: none;
}

.combat-inventory-container {
	display: grid;
	grid-template-columns: 310px 200px 310px;
	gap: 1em;
	justify-content: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.combat-inventory-food-stats {
	display: grid;
	/* grid-auto-rows: fit-content(100%); */
	grid-auto-rows: minmax(min-content, max-content);
	/* align-items: start; */
	/* grid-template-rows: repeat(auto-fit, 50px); */
}

.combat-inventory-food-stat {
	background-image: url('/images/ui/frame_box_dark.png');
	background-size: 100% 100%;
}

.combat-inventory-food-stat-content {
	padding: 10px;
	font-size: 12px;
	display: grid;
	position: relative;
	grid-template-columns: 40px 1fr;
	grid-template-rows: repeat(2, 20px);
}

.combat-inventory-food-stat-icon {
	grid-row: 1 / 3;
	height: 80% !important;
	width: 80% !important;
}

.core-container-combat .react-tabs__tab-panel {
	min-width: 23px;
}

.combat-gear-inventory-wrapper {
	width: 310px;
}

.combat-gear-inventory {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	align-content: space-evenly;
	position: relative;
	overflow-y: auto;
	height: 400px;
	justify-content: center;
}

/* combat consumable inventory */
.combat-consumable {
	position: relative;
	flex-basis: calc(25% - 10px);
	margin: 2px;
	box-sizing: border-box;
}

/* .combat-consumable::before {
	content: "";
	display: block;
	padding-top: 100%;
} */

/* combat consumable inventory empty slot */
.combat-empty-slot {
	height: 45px;
	width: 45px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	border: 2px solid hsla(0, 0%, 75.3%, 0.2);
	border-radius: 8px;
}

.right-panel-content .combat-gear-container {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 20;
	overflow-y: auto;
}

/*  */
.play-area-container .combat-gear-container {
	position: relative;
	/* 	display: flex;
	flex-wrap: wrap; */
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(max(250px, 25%), 1fr));
	column-gap: 5px;
	height: 100%;
	width: 100%;
	z-index: 20;
}

.play-area-container .combat-gear-item {
	position: absolute;
	height: 50px;
	width: 50px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50%;
}

.equipped-item {
	height: 48px !important;
	width: 48px !important;
}

.death-animation {
	animation: fadeOut 1.5s forwards;
}

.high-performance .death-animation {
	animation: none;
	visibility: hidden;
	display: none;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		filter: grayscale(0);
	}
	20% {
		filter: grayscale(100%);
	}
	100% {
		opacity: 0;
		filter: grayscale(100%);
	}
}

@keyframes spinAndScale {
	0% {
		transform: rotate(0deg) scale(1);
		filter: grayscale(0);
		opacity: 1;
	}
	20% {
		transform: rotate(180deg) scale(1.5);
		filter: grayscale(50%);
		opacity: 0.5;
	}
	40% {
		transform: rotate(360deg) scale(0.5);
		filter: grayscale(100%);
		opacity: 0;
	}
	60% {
		transform: rotate(540deg) scale(1.5);
		filter: grayscale(50%);
		opacity: 0.5;
	}
	80% {
		transform: rotate(720deg) scale(1);
		filter: grayscale(0);
		opacity: 1;
	}
	100% {
		transform: rotate(900deg) scale(0.5);
		filter: grayscale(100%);
		opacity: 0;
	}
}

.combat-fight {
	height: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 1fr 4.5fr;
	column-gap: 5px;
}

.combat-fight-group {
	height: 100%;
	position: relative;
	display: grid;
	grid-template-columns: 200px 2.5fr 4.5fr;
	column-gap: 5px;
}

.combat-fight-container {
	height: 100%;
	position: relative;
}

.combat-player-area img,
.combat-monster-area img {
	width: 100%;
	/*     height: 100%; */
	/*     display: block; */
	margin-right: 0 !important;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
}

.combat-monster-area .combat-fight-image-container > img {
	width: 100% !important;
}

.combat-monster-area,
.combat-player-area {
	/* align-self: end; */
	justify-self: center;
	width: 100%;
	position: relative;
	height: 92%;
	/*     justify-items: center; */
	/*     margin-left: auto;
    margin-right: auto; */
	/*     justify-self: center; */
	/* position: relative; */
}

.combat-player-area {
	max-width: 200px;
}

.combat-monster-area {
	grid-template-columns: repeat(5, min(100px, 20%));
}

.combat-monster-area > div,
.combat-player-area > div {
	display: grid;
	width: 100%;
	padding: 0;
	justify-items: center;
	position: absolute;
	bottom: 0;
	/*     height: 100%;
    width: 100%; */
	/*     margin-left: auto;
    margin-right: auto; */
}

.combat-monster-area {
	display: grid;
	grid-template-columns: repeat(5, min(125px, 20%));
	grid-template-rows: repeat(5, 20%);
	column-gap: calc((100% - 625px) / 4);
	padding-right: min(30%, 275px);
	top: calc(60% - 50px);
	padding-bottom: 15px;
	justify-items: center;
	height: 40%;
	max-height: 800px;
}

.combat-monster-area .combat-fight-image-container {
	width: 100%;
}

.combat-monster-area > div {
	width: 200%;
	min-width: 100px;
	grid-column: 3 / 3;
	grid-row: 3 / 3;
}
.combat-monster-area > div:nth-last-child(1) {
	grid-column: 5 / 5;
	grid-row: 5 / 5;
}
.combat-monster-area > div:nth-last-child(2) {
	grid-column: 2 / 2;
	grid-row: 4 / 4;
}
.combat-monster-area > div:nth-last-child(3) {
	grid-column: 4 / 4;
	grid-row: 3 / 3;
}
.combat-monster-area > div:nth-last-child(4) {
	grid-column: 3 / 3;
	grid-row: 4 / 4;
}
.combat-monster-area > div:nth-last-child(5) {
	grid-column: 4 / 4;
	grid-row: 2 / 2;
}
.combat-monster-area .Chicken {
	width: 100%;
	min-width: 50px;
}

.combat-monster-area .Hen {
	width: 133%;
	min-width: 75px;
}

.combat-monster-area .Just {
	width: 166%;
	min-width: 100px;
}

.combat-monster-area .Phoenix {
	width: 200%;
	min-width: 125px;
}
.combat-monster-area .Limpe {
	width: 175%;
	min-width: 110px;
}

.combat-monster-area .Rat {
	width: 100%;
	min-width: 50px;
}

.combat-monster-area .Marshall {
	width: 150%;
	min-width: 75px;
}

.combat-monster-area .Cow {
	width: 300%;
	min-width: 150px;
}

.combat-monster-area .Imp {
	width: 150% !important;
	min-width: 75px !important;
}

.combat-monster-area .Goblin {
	width: 150%;
	min-width: 75px;
}

.combat-monster-area .Hobgoblin {
	width: 175%;
	min-width: 87.5px;
}

.combat-monster-area .Big,
.combat-monster-area .Chef {
	width: 200%;
	min-width: 100px;
}

.combat-monster-area .Guard {
	width: 220%;
	min-width: 125px;
}

.combat-monster-area .Black {
	width: 300%;
	min-width: 150px;
	margin-top: -30%;
}

.combat-monster-area .Glass {
	width: 300%;
	min-width: 200px;
}

.combat-monster-area .Imbued,
.combat-monster-area .Obsidian,
.combat-monster-area .Titan {
	width: 350%;
	min-width: 175px;
	margin-top: -30%;
}

.combat-monster-area .Demon {
	width: 300%;
	min-width: 150px;
}

.combat-monster-area .Lesser {
	width: 225%;
	min-width: 112.5px;
}

.combat-monster-area .Deadly {
	width: 175%;
	min-width: 87.5px;
}

.combat-monster-area .Red {
	background-color: transparent !important;
	width: 250%;
	min-width: 125px;
}

.combat-monster-area .Blood {
	width: 300%;
	min-width: 150px;
}
.combat-monster-area .Boar,
.combat-monster-area .Wolf {
	width: 250%;
	min-width: 125px;
}

.combat-monster-area .Nox,
.combat-monster-area .ANGY {
	width: 350%;
	min-width: 125px;
}

.combat-monster-area .Snowman {
	width: 225%;
	min-width: 112.5px;
}

.combat-monster-area .Overseer,
.combat-monster-area .Greater {
	width: 300%;
	min-width: 150px;
}

.combat-monster-area .Spriggan {
	width: 300%;
	min-width: 150px;
	margin-top: -55%;
}
.combat-monster-area .Mysterious {
	width: 200%;
	min-width: 100px;
	margin-top: 0;
}
.combat-monster-area .Ice,
.combat-monster-area .Moss,
.combat-monster-area .Fire,
.combat-monster-area .Bone,
.combat-monster-area .Chaos,
.combat-monster-area .Giant,
.combat-monster-area .Enraged {
	width: 400%;
	min-width: 200px;
	margin-top: -50%;
}

.combat-monster-area .Krampus {
	width: 400%;
	min-width: 200px;
	margin-top: -50%;
}

.combat-monster-area .Aberrant {
	width: 300%;
	min-width: 150px;
	margin-top: -75%;
}

.combat-monster-area .Abomination {
	margin-top: -70%;
}

.combat-monster-area .DPS {
	width: 350%;
	min-width: 150px;
}

.combat-monster-area .Elven {
	width: 350%;
	min-width: 200px;
}

.combat-monster-area .Royal {
	width: 300%;
	min-width: 200px;
}

.combat-monster-area .Christmas {
	width: 225%;
	min-width: 112.5px;
}

.combat-monster-area .Corrupted {
	width: 250%;
	min-width: 125px;
}

.combat-monster-area .Infected {
	width: 225%;
	min-width: 112.5px;
}

.combat-monster-area .Gummy {
	width: 225%;
	min-width: 112.5px;
}

.combat-monster-area .Reanimated {
	width: 300%;
	min-width: 150px;
}

.combat-monster-area .Restless {
	width: 350%;
	min-width: 150px;
}

.combat-monster-area .INFO {
	width: 450%;
	min-width: 225px;
}

.combat-monster-area .Shrimp {
	width: 450%;
	min-width: 225px;
}

.combat-fight-image-container {
	position: relative;
	text-align: center;
	width: 100%;
	/* width: 200px; */
	/* height: 385px; */
}
.combat-fight-image-container-avatar {
	margin-top: auto;
	min-width: 100px;
	min-height: 192.5px;
}

.combat-player-area .combat-fight-image-container {
	width: 100%;
	margin-bottom: 1%;
	margin-left: 5%;
}

.combat-fight-image-container > div {
	text-align: center;
	position: absolute;
	font-size: 25px;
	line-height: 50px;
	height: 12.99%;
	width: 25%;
	top: 25%;
	left: 35%;
}

.combat-fight-image-container-group {
	position: relative;
	text-align: center;
	width: 100%;
	transform: scale(0.4);
}

.combat-fight-image-container-group > div {
	text-align: center;
	position: absolute;
	font-size: 25px;
	line-height: 35px;
	height: 40px;
	width: 40px;
	left: 35%;
	top: 25%;
}

.combat-avatar-group-panel {
	width: 200px;
	position: relative;
}

.attack-speed-animation {
	/*     visibility: visible; */
	transform: rotateZ(360deg);
	will-change: transform;
	animation: attackspeed-countdown;
	animation-timing-function: linear;
	animation-play-state: running;
	animation-iteration-count: 1;
	background-image: url(/images/ui/ProgressBars/blue_fill_edited.png);
	background-size: 100% 100%;
	filter: brightness(0.8);

	visibility: visible;
}

.high-performance .attack-speed-animation {
	animation: none;
}

@keyframes attackspeed-countdown {
	from {
		transform: scaleX(0);
		/*         background-color: lightblue; */
		/* visibility: visible; */
	}
	to {
		transform: scaleX(1);
	}
}

.base-monster-style {
	pointer-events: none;
}

.group-combat-main {
	position: relative;
	height: 100%;
	width: 100%;
}

.group-combat-party {
	text-align: center;
	overflow-y: scroll;
}

.combat-raid-scaling {
	transform: scale(0.67);
}

.combat-group-avatars .combat-fight-image-container {
	position: absolute;
	width: 40%;
}

.combat-group-avatar:nth-child(0) {
	left: 70%;
	top: 20%;
	z-index: 5;
}
.combat-group-avatar:nth-child(1) {
	left: 40%;
	top: 2%;
	z-index: 2;
}
.combat-group-avatar:nth-child(2) {
	left: 45%;
	top: 40%;
	z-index: 4;
}
.combat-group-avatar:nth-child(3) {
	left: 20%;
	top: 20%;
	z-index: 3;
}
.combat-group-avatar:nth-child(4) {
	left: 0;
	z-index: 1;
}
.combat-group-avatar:nth-child(5) {
	left: 30%;
	top: 50%;
	z-index: 6;
}
.combat-group-avatar:nth-child(6) {
	left: 2%;
	top: 50%;
	z-index: 5;
}
.combat-group-avatar:nth-child(7) {
	left: 5%;
	top: 25%;
	z-index: 2;
}
.combat-group-avatar:nth-child(8) {
	left: 45%;
	top: 10%;
	z-index: 9;
}
.combat-group-avatar:nth-child(9) {
	left: 45%;
	top: 55%;
	z-index: 11;
}
.combat-group-avatar:nth-child(10) {
	left: 50%;
	top: 30%;
	z-index: 9;
}

.combat-group-avatars {
	height: 100%;
	width: 100%;
	position: relative;
	max-height: 800px;
	margin: auto;
}

.combat-group-avatars > div {
	max-width: 200px;
}

.combat-info-bar {
	width: 300px;
	z-index: 130;
	position: absolute;
	top: -80px;
}

.combat-info-bar-health-progress-bar .idlescape-progress-bar-filler {
	margin-top: 6px;
	filter: brightness(1.5);
	height: 90%;
}

.combat-player-area .combat-info-bar {
	z-index: 131;
}

.combat-player-area .combat-info-bar,
.combat-monster-area .combat-info-bar {
	position: sticky;
	width: 300px;
	height: 100%;
	top: 0;
	left: 0;
	margin-right: -300px;
	margin-left: -300px;
}

.combat-group-info-container {
	width: 100%;
	min-height: 100%;
	/* background-color: rgba(0, 0, 0, 0.308); */
	display: grid;
	row-gap: 10px;
	grid-auto-rows: 115px;
	grid-template-columns: 200px;
}

.combat-group-info-player {
	text-align: center;
	height: 100%;
	width: 100%;
	padding: 5px;
	background-image: url(/images/ui/frame_box.png);
	background-size: 100% 100%;
	position: relative;
}

.combat-group-info-player:hover {
	box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7), inset 0 0 20px 0 rgba(255, 255, 255, 0.7);
	border-radius: 10px;
}

.combat-group-info-player .combat-info-bar {
	transform: scale(0.6);
	position: absolute;
	top: 32px;
	left: -53px;
}

.combat-group-info-player .combat-info-bar-name {
	font-size: 24px;
	font-weight: normal;
}

.combat-group-info-player .combat-info-bar-health-text {
	font-size: 20px;
	top: 22px;
}

.combat-group-info-player-mugshot-container {
	margin-left: 10px;
	height: 60px;
	width: 60px;
}

.combat-group-info-player-mugshot-container > img {
	height: 100%;
	width: 100%;
}

.combat-group-highlight-player {
	box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.7), inset 0 0 20px 0 rgba(255, 255, 255, 0.7);
	border-radius: 10px;
	transform: scale(1.1);
}

.combat-avatar-mugshot-container .combat-fight-image-container {
	clip-path: inset(-120px 50px 305px 50px);
	width: 200px;
	height: 385px;
}

.combat-avatar-mugshot-container {
	position: absolute;
	right: -50px;
	top: 20px;
}

.combat-group-info-player .combat-avatar-mugshot-container .combat-fight-image-container {
	clip-path: inset(-96px 40px 244px 40px);
	width: 160px;
	height: 308px;
}

.combat-group-info-player .combat-avatar-mugshot-container {
	left: -30px;
	top: 10px;
}

.combat-splashes-container {
	/* <the old stuff> */
	--offset: -60px;
	--degree-offset: 30deg;
	display: flex;
	/* these three rules define the center point*/
	align-items: center;
	justify-content: center;
	/* padding: 0 10% 30% 0; */
	min-height: 180px;
	z-index: 101;
}

.combat-splashes-container.compact {
	--compact-offset-x: 35px;
	--compact-offset-y: 20px;
	padding: 0;
}

.combat-splashes-container .combat-splash {
	/* background-color: white; */
	/* border: 2px solid white; */
	position: absolute;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	background-size: cover;
}

.combat-splash-0 {
	transform: rotate(calc(60deg * 1 + var(--degree-offset))) translate(var(--offset))
		rotate(calc(-1 * (60deg * 1 + var(--degree-offset))));
}
.combat-splash-1 {
	transform: rotate(calc(60deg * 2 + var(--degree-offset))) translate(var(--offset))
		rotate(calc(-1 * (60deg * 2 + var(--degree-offset))));
}
.combat-splash-2 {
	transform: rotate(calc(60deg * 3 + var(--degree-offset))) translate(var(--offset))
		rotate(calc(-1 * (60deg * 3 + var(--degree-offset))));
}
.combat-splash-3 {
	transform: rotate(calc(60deg * 4 + var(--degree-offset))) translate(var(--offset))
		rotate(calc(-1 * (60deg * 4 + var(--degree-offset))));
}
.combat-splash-4 {
	transform: rotate(calc(60deg * 5 + var(--degree-offset))) translate(var(--offset))
		rotate(calc(-1 * (60deg * 5 + var(--degree-offset))));
}
.combat-splash-5 {
	transform: rotate(calc(60deg * 6 + var(--degree-offset))) translate(var(--offset))
		rotate(calc(-1 * (60deg * 6 + var(--degree-offset))));
}

.compact > .combat-splash-0 {
	transform: translate(calc(0.5 * var(--compact-offset-x)), calc(1 * var(--compact-offset-y)));
}

.compact > .combat-splash-1 {
	transform: translate(calc(-0.5 * var(--compact-offset-x)), calc(-1 * var(--compact-offset-y)));
}

.compact > .combat-splash-2 {
	transform: translate(calc(1.5 * var(--compact-offset-x)), calc(-1 * var(--compact-offset-y)));
}

.compact > .combat-splash-3 {
	transform: translate(calc(-1.5 * var(--compact-offset-x)), calc(1 * var(--compact-offset-y)));
}

.compact > .combat-splash-4 {
	transform: translate(calc(2.5 * var(--compact-offset-x)), calc(1 * var(--compact-offset-y)));
}

.compact > .combat-splash-5 {
	transform: translate(calc(-2.5 * var(--compact-offset-x)), calc(-1 * var(--compact-offset-y)));
}

.combat-splash-heal {
	background-image: url(/images/combat/splashes/healing_splash.png);
}

.combat-splash-miss {
	background-image: url(/images/combat/splashes/miss_splash.png);
}

.combat-splash .combat-splash-image {
	height: 100%;
	width: 50px;
	object-fit: contain;
}

.combat-splash > span {
	position: absolute;
	left: 0;
	right: 0;
	top: 5%;
	bottom: 0;
	line-height: 50px;
	white-space: nowrap;
}

/* .combat-splash-physical {
	background-image: url(/images/combat/splashes/physical_splash.png);
} */

.combat-splash-critical {
	font-size: 40px;
}

.combat-splash-critical > span {
	line-height: 60px;
}

/* .combat-splash-range {
	background-image: url(/images/combat/splashes/range_splash.png);
}

.combat-splash-range-critical {
	background-image: url(/images/combat/splashes/range_splash_critical.png);
}

.combat-splash-magic {
	background-image: url(/images/combat/splashes/magic_splash.png);
}

.combat-splash-magic-critical {
	background-image: url(/images/combat/splashes/magic_splash_critical.png);
}

.combat-splash-chaos {
	background-image: url(/images/combat/splashes/chaos_splash.png);
}

.combat-splash-chaos-critical {
	background-image: url(/images/combat/splashes/chaos_splash_critical.png);
}

.combat-splash-poison {
	background-image: url(/images/combat/splashes/poison_splash.png);
}

.combat-splash-poison-critical {
	background-image: url(/images/combat/splashes/poison_splash_critical.png);
}

.combat-splash-fire {
	background-image: url(/images/combat/splashes/fire_splash.png);
}

.combat-splash-fire-critical {
	background-image: url(/images/combat/splashes/fire_splash_critical.png);
}

.combat-splash-ice {
	background-image: url(/images/combat/splashes/ice_splash.png);
}

.combat-splash-ice-critical {
	background-image: url(/images/combat/splashes/ice_splash_critical.png);
}

.combat-splash-lightning {
	background-image: url(/images/combat/splashes/lightning_splash.png);
}

.combat-splash-lightning-critical {
	background-image: url(/images/combat/splashes/lightning_splash_critical.png);
}*/

.combat-group-info-player-dead,
.combat-group-highlight-player-dead {
	filter: brightness(0.5) grayscale(1);
}

.combat-group-avatar-targeted-player {
	filter: drop-shadow(0 0 30px rgba(255, 0, 0, 1));
}

.combat-group-info-player.combat-group-info-player-targeted-player {
	filter: drop-shadow(0 0 10px rgba(255, 0, 0, 1));
}

.combat-group-avatars .combat-splashes-container {
	font-weight: bold;
	top: 40px !important;
	/* font-size: 28px; */
	/* text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000 !important; */
}

.combat-gear {
	position: relative;
	height: 402px;
	z-index: 10;
	min-width: 300px;
}

.combat-gear > img {
	height: 230px;
	min-width: 174px;
	margin: 36px auto 80px;

	left: 50%;
	transform: translate(-50%);
	display: unset;
	position: relative;
}

.combat-gear-item {
	background-image: url(/images/ui/frame_box_dark.png) !important;
	background-size: cover !important;
	background-position: center !important;
}

.combat-gear-item {
	/* border: 2px solid hsla(0, 0, 75.3%, 0.2);
	background-color: #333; */
	position: absolute;
	height: 50px;
	width: 50px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50%;
}

.combat-abilities-header {
	position: relative;
	text-shadow: 0 0 5px black;
	height: 40px;
	line-height: 28px;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 80%);
	border-top-left-radius: 5px;
	padding-left: 5px;
	margin-bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.combat-abilities {
	margin-top: 10px;
	min-height: 150px;
	height: fit-content;
	max-width: 500px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.combat-ability {
	position: relative;
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));

	background-image: url(/images/ui/frame_box_dark.png) !important;
	background-size: cover !important;
	background-position: center !important;

	text-align: center;
}

.combat-ability-title {
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin-left: auto;
	margin-right: auto;
	font-size: 14px;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.combat-ability-name {
	position: absolute;
	width: 100%;
	top: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 2px;
	font-size: 14px;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	color: white;
}

.combat-ability-name-learned {
	position: absolute;
	width: 100%;
	top: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 2px;
	font-size: 14px;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	color: yellow;
}

.combat-ability-icon {
	height: 80%;
	width: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-size: contain;
	box-shadow: 0 0 5px 2px rgb(23 26 31), 0 0 5px 2px rgb(23 26 31) inset;
	opacity: 0.7;
	border-radius: 5px;
}

.combat-ability-icon::before {
	/* content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	box-shadow: 5px 5px 10px 10px pink; */
	/* display: block; */

	/* top: 0; */
}

.idlescape-tooltip.combat-ability-tooltip .item-tooltip {
	background-image: none !important;
	border-image: none !important;
	border: none;
	background-color: rgba(255, 255, 255, 0) !important;
}

/*@media (max-width: 1500px) {
	.combat-fight {
		transform: scale(0.8);
		width: 125%;
		height: 125%;
		left: -12.5%;
		top: -12.5%;
	}
}

@media (max-width: 600px) {
	.combat-fight {
		transform: scale(0.5);
		width: 200%;
		height: 200%;
		left: -50%;
		top: -50%;
	}
}*/

.mobile-layout .combat-info-bar {
	transform: scale(0.6);
}

.mobile-layout .combat-player-area .combat-info-bar {
	left: -50px;
}

.mobile-layout .combat-info-bar-name {
	font-size: 24px;
}

.mobile-layout .combat-info-bar-health-text {
	font-size: 20px;
}

.mobile-layout .combat-fight-group {
	grid-template-columns: 2.5fr 4.5fr;
	grid-template-rows: 240px 1fr;
	row-gap: 10px;
}

.mobile-layout .combat-group-info-container {
	grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	grid-column: 1/-1;
	row-gap: 5px;
}

.mobile-layout .combat-splashes-container {
	--offset: -40px;
	min-height: 100px;
}

.mobile-layout .combat-splash {
	font-size: 20px;
}

.mobile-layout .combat-splash .combat-splash-image {
	width: 40px;
	height: 40px;
}

.mobile-layout .combat-splash-critical {
	font-size: 30px;
}

.mobile-layout .combat-splash-critical > span {
	line-height: 50px;
}

.mobile-layout .combat-splash-critical > .combat-splash-image {
	height: 55px;
	width: 55px;
}

.combat-zones-container {
	overflow: hidden;
}

.combat-zones-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	column-gap: 20px;
	row-gap: 20px;
}

.combat-zone-wrapper {
	padding: 7px 12px;
}

.combat-zone {
	aspect-ratio: 19 / 10;
	background-size: 100% 100%;
	border-radius: 5px;

	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5), 0 0 2px 2px rgba(39, 43, 50, 0.9) inset !important;

	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9));

	transition: scale 0.1s;
}

.combat-zone-text {
	position: relative;
	text-shadow: 0 0 5px black;
	line-height: 28px;
	background: linear-gradient(to right, rgb(0, 0, 0) 50%, rgb(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0) 100%);
	margin-top: 5px;
	margin-left: 10px;
	border-top-left-radius: 5px;
	padding-left: 5px;
	padding-right: 30px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.combat-zone-text-name {
	min-width: 120px;
	max-width: 100%;
}

.group-size-combatzone,
.combat-zone-elite-challenge {
	position: relative;
	text-shadow: 0 0 5px black;
	line-height: 28px;
	background: linear-gradient(to left, rgb(0, 0, 0) 50%, rgb(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 0) 100%);
	margin-top: 5px;
	margin-right: 10px;
	border-top-right-radius: 5px;
	padding-left: 30px;
	padding-right: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.combat-stats-wrapper {
	/* min-height: 400px; */
	/* height: 100%; */
	overflow-y: auto;
	grid-row: span 2;
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	/* min-height: 402px; */
}

.combat-stats-buttons {
	display: grid;
	width: 80%;
	grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
	margin-left: auto;
	margin-right: auto;
	column-gap: 5px;
}

.combat-stats-button {
	padding: 0;
}

.combat-stats {
	/* display: grid;
	grid-template-columns: 1fr;
	column-gap: 10px;
	row-gap: 5px; */
	/* grid-template-rows: minmax(400px, 1fr); */
	/* align-content: space-evenly;
	justify-content: center; */
	overflow-y: auto;
	width: 100%;
	/* min-height: 200px; */
	height: 100%;
}

.combat-stat {
	position: relative;
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	/* display: flex;
	width: fit-content; */
	/* grid-template-columns: 1fr 60px; */
}

.combat-stat-group-heading {
	font-size: 14px;
	text-shadow: 2px 2px rgba(0, 0, 0, 0.66);
}

.combat-stat-name {
	justify-self: start;
}

.combat-stat-sub-name {
	font-style: italic;
	padding-left: 5px;
	padding-right: 10px;
}

.combat-stat-value {
	justify-self: right;
	font-style: italic;
	padding-right: 2px;
}

.combat-stat-group-main {
	height: 90px;
	padding: 10px 15px 10px 10px;
	display: grid;
	column-gap: 10px;
	grid-template-columns: auto 1fr 1fr; /*

	grid-template-columns: 0.3fr 0.3fr 0.3fr; */
}

.combat-stat-health {
	grid-row: span 4;
	text-align: center;
	font-weight: bold;
}

.combat-stat-health-icon {
	width: 50px;
	height: 50px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.combat-attack-type {
	font-size: 8px;
}

.combat-gear-container-wrapper {
	height: 100%;
}

/* .right-panel-content .combat-gear > img {
	left: 50%;
	transform: translate(-50%);
	display: unset;
	position: relative;
} */

.combat-inventory-food-stat-name {
	font-weight: bold;
}

.combat-fight-image-container > .combat-splashes-container {
	/*^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ needed to have enough specificity
	  overwrites rules from the mentioned class above */
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.combat-splash-critical > .combat-splash-image {
	height: 70px;
	width: 70px;
}

.mobile-layout .combat-inventory-container {
	grid-template-columns: auto;
	justify-items: center;
}

.mobile-layout .combat-inventory-food-stats {
	order: -1;
	grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
	width: 100%;
}

.combat-instance-stats-container {
	display: flex;
	gap: 1em;
	justify-content: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.combat-instance-stats-row {
	display: flex;
	gap: 1em;
	justify-content: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.combat-instance-stats {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
}

.combat-instance-stats-container {
	display: flex;
	gap: 1em;
	justify-content: center;
	width: 100%;
	min-width: 600px;
	margin-left: auto;
	margin-right: auto;
	padding: 1%;
}

.combat-instance-stats-table {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	gap: 1em;
	justify-content: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.combat-instance-ability-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 50px;
	min-width: 50px;
}

.combat-instance-ability-image {
	height: 50px;
	width: 50px;
}

.combat-instance-header {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2em;
	font-weight: bold;
}

.combat-instance-subheader {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
}

.combat-instance-monster-image {
	max-height: 50px;
	max-width: 50px;
	object-fit: contain;
}

.combat-instance-monster-header {
	display: grid;
	grid-template-columns: 20% 75% 5%;
	align-items: center;
	justify-content: center;
	font-size: 2em;
	font-weight: bold;
	grid-gap: 15px;
	padding: auto;
	margin: auto;
}

.combat-instance-monster-killcount {
	display: grid;
	grid-template-columns: 10% 90%;
	align-items: center;
	justify-content: center;
	font-size: 1.25em;
	font-weight: bold;
	grid-gap: 15px;
	padding: auto;
	margin: auto;
}
