.status-bar {
	text-align: center;
	white-space: nowrap;
}

.status-action {
	display: grid;
	grid-template-columns: 1fr max-content minmax(max-content, 1fr);
	gap: 5px;
	margin-top: -10px;
	height: 30px;
	align-items: center;
}

.status-message {
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 5px;
}

.mobile-layout .status-action {
	margin-top: 0;
	height: 25px;
}

.status-name {
	max-width: 250px;
	display: flex;
	grid-column: 2;
	justify-self: center;
}

.status-buttons {
	grid-column: 3;
	justify-self: end;
}

.buffs-container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 42px;
	justify-items: center;
	justify-content: center;
	text-align: center;
}

.buffs-container > div {
	position: relative;
}

.buff {
	display: block;
	position: relative;
	border-radius: 5px;
	height: 40px;
	width: 40px;
	padding: 2px;
	text-align: center;
	cursor: pointer;
	z-index: 10;
}

.buff > div {
	z-index: 1;
	font-weight: bolder;
	position: absolute;
	left: 50%;
	bottom: 5px;
	transform: translateX(-50%);
	text-align: center;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size: 16px;
	white-space: nowrap;
}

.buff > img {
	object-fit: contain;
	height: 100%;
	width: 100%;
	bottom: 0;
	position: relative;
}

.buff-personal {
	border: 2px solid #573a6f;
	background-color: #1e1829;
}

.buff-personal > img {
	filter: hue-rotate(0deg);
}

.buff-shrine {
	border: 2px solid #3a4b6f;
	background-color: #182129;
}

.buff-shrine > img {
	filter: hue-rotate(307deg);
}

.buff-shrine-inactive {
	filter: grayscale(100%);
}

.buff-madness {
	filter: hue-rotate(80deg);
}

.buff-donation {
	border: 2px solid #496f3a;
	background-color: #202918;
}

.buff-donation > img {
	filter: hue-rotate(190deg);
}

.buff-event {
	border: 2px solid #6f573a;
	background-color: #291e18;
}

.buff-event > img {
	filter: hue-rotate(121deg);
}

.buff-christmas {
	border: 1px solid #5198fc;
	box-shadow: 0 0 12px #78aefa;
	background-color: #78affc91;
}

.buff-admin {
	border: 2px solid #6f3a43;
	background-color: #29181e;
}

.buff-admin > img {
	filter: hue-rotate(76deg);
}

.easter-button {
	position: absolute;
	top: 120%;
	z-index: 1;
}

.buff-title {
	text-align: center;
	font-size: 1.5rem;
	font-weight: bolder;
	text-shadow: 0 0 5px black;
	-webkit-text-stroke: 0.25px black;
	padding-top: 5px;
}
